<template>
  <div>
    <popup
        @close="() => {showDeletePrompt = false;}"
        v-if="showDeletePrompt"
    >
      <delete-prompt slot="popupMainContent"
                     @abort="() => {showDeletePrompt = false; toBeDeletedAsset = null;}"
                     @confirm="() => {showDeletePrompt = false; if(toBeDeletedAsset) {deleteFilter();}}"
      >
        <loading-panel v-if="loading" />
        <div v-else-if="instanceList && instanceList.length">
          <br >{{ $t('willAlsoBeDeleted') }}
          <div :key="key" v-for="(item, key) in instanceList">
            {{ $t('ExportedProfiles') }}: {{ item.name ? item.name : item.asset.name }}<br >
            <span class="lighter">{{ $t('Created') }}:</span> {{ dateTimeFromISO(item.createdAt) }}<br ><br >
          </div>
        </div>
      </delete-prompt>
    </popup>
    {{ content }}
    <h1 v-if="!filterId">{{ $t('createNewType', {type: $t('type.exportProfile')}) }}</h1>
    <h1 v-else>{{ $t('updateType', {type: $t('type.exportProfile')}) }}</h1>
    <organization-switcher
        @setOrganization="id => localOrganizationId = id"
        @setTeam="id => teamId = id"
        @unsetTeam="teamId = ''"
        v-if="!organizationId"
        class="mb-2"
    />
    <input
        type="text"
        :class="['form-text mt-4', $v.name.$error ? 'form-group--error' : '']"
        :id="'name'"
        v-model="name"
        :placeholder="$t('enternamehere')"

    >
    <div class="form-error" v-if="!$v.name.required && $v.name.$error">{{ $t('errors.required') }}</div>
    <div class="form-error" v-if="!$v.name.alphaNumSpace">{{ $t('errors.alphaNumSpaceOnly') }}</div>

    <div class="form-error" v-if="!$v.name.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>

    <div class="form-error" v-if="!$v.name.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
    <div class="form-error" v-if="!$v.name.isUnique">{{ $t('errors.sfxdataNameAlreadyExists') }}</div>
    <div class="form-error" v-if="error">{{ $t(error) }}</div>

    <div class="inline-edit-label mt-3">
      <b>{{ $t('description') }}</b>
    </div>

    <textarea
        :class="['form-text', $v.description.$error ? 'form-group--error' : '']"
        :id="'description'"
        v-model="description"
        :placeholder="$t('enterdeschere')"
    />
    <div class="form-error" v-if="!$v.description.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>

    <team-attacher
        v-if="filterId && $store.getters.getFilterOrganization(filterId)"
              slot="info"
              class="mt-3 mb-3"
              :id="filterId"
              :teams="localTeams"
              store-name="Asset"
              :limit-by-organization-id="$store.getters.getFilterOrganization(filterId)"
              @added="loadAssetTeams"
              @removed="loadAssetTeams"
          />

    <info-panel
        class="mt-3 light-header darker-panel"
                info-panel-header="DefineProfile"
                :collapsible="true"
                :initially-collapsed="true"
                preview-id="filteredaddform"
    >
      <div slot="info">
        <div class="ml-2">
          <meta-set-selector
              class="mt-1"
              v-if="localOrganizationId"
              :organization-id="localOrganizationId"
              :pre-selected-meta-set="metaSetId ? metaSetId : null"
              @update="setMetaSet"
              permission="readMetaSet"
              :show-save-button="false"
              :usev-h-u-b-meta-sets="true"
          />
          <by-meta-fields-filter
              class="mt-3"
              v-if="metaSetId"
              :existing-filter="filter ? filter : {}"
              :meta-set-id="metaSetId"
              :organization-id="localOrganizationId"
              @filterChange="filterChange"
          />
        </div>
        <div class="col-12 col-md-8 mt-3">
      <label class="mr-1">{{ $t('Mode') }}</label>
      <tile-button v-if="!filterId || hasLoaded" @trigger="(input) => {mode = input.value}" :initial-active-tiles="[mode]" :tiles="['include', 'exclude']" :values="['include', 'exclude']" />
    </div>
      </div>
    </info-panel>
    <info-panel
        class="mt-1 light-header darker-panel"
                info-panel-header="DefineMerging"
                :collapsible="true"
                :initially-collapsed="true"
                preview-id="merge"
    >
      <div slot="info">
        <div class="ml-2">
          <meta-set-selector
              class="mt-1"
              v-if="localOrganizationId"
              :organization-id="localOrganizationId"
              :pre-selected-meta-set="mergeMetaSetId ? mergeMetaSetId : null"
              @update="setMergingMetaSet"
              permission="readMetaSet"
              :show-save-button="false"
              :usev-h-u-b-meta-sets="true"
          />
          <by-meta-fields-filter
              class="mt-3"
              v-if="mergeMetaSetId"
              :existing-filter="merge ? merge : {}"
              :meta-set-id="mergeMetaSetId"
              :organization-id="localOrganizationId"
              @filterChange="mergeChange"
          />
        </div>
      </div>
    </info-panel>

     <info-panel
        class="mt-1 light-header darker-panel"
                info-panel-header="Korrekturen"
                :collapsible="true"
                :initially-collapsed="true"
                preview-id="filteredaddform"
    >
      <div slot="info">
    <div class="col-12 mt-3">
       <div class="form-item mt-3 mb-3">
                <label>{{ $t('pivotPlacement') }} (global)</label><info-helper class="ml-1" text="pivotComment" />
                <select class="custom-select dark" v-model="pivotPlacement">
                  <option :key="key" :value="key" v-for="(key) in pivotPositions">{{ key }}</option>
                </select>
          </div>
          <label>{{ $t('pivotPlacement') }} (local)</label>
          <div class="form-group">
            <input id="checkbox" class="checkbox float-left mr-2" ref="checkbox" type="checkbox"
                   v-model="usePivotValues">
            <div class="ml-4 inline-edit-label" for="checkbox">{{ $t('UseMetaSetValuesForPivots') }}</div>
          </div>
      <div class="w-100 mb-5" />
      <label class="mr-1">{{ $t('axixMultiplier') }}</label><info-helper text="axisMultiplierDesc" /><br >
      <div class="d-flex">
        <div class="mr-1"><label>x</label><input type="number" class="form-text" v-model="axisMultiplier[0]" ></div>
        <div><label>y</label><input type="number" class="form-text" v-model="axisMultiplier[1]" ></div>
        <div class="ml-1"><label>z</label><input type="number" class="form-text" v-model="axisMultiplier[2]" ></div>
      </div>
    </div>
      </div></info-panel>
    <div class="w-100 mt-5" />
    <Button
        class="mb-3"
        :deactivated="!enableSaving"
        @click="() => { if(filterId) update(); else save(); }">
      <icon v-if="enableSaving" type="save"/><icon v-else type="ban" /> {{ $t(!filterId ? 'SAVE' : 'SAVE') }}
    </Button>
    <Button
        class="ml-2 mb-3"
        @click="abort">
      <icon type="times"/> {{ $t('abort') }}
    </Button>
    <Button
        v-if="filterId"
        @click="deleteFilter(filterId)"
        class="ml-2 brighter float-right"
    >
{{ $t('delete') }} <icon type="trash-alt" />
</Button>
  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import {alphaNumSpace} from '../customValidators';
import Button from "@/components/forms/Button";
import Icon from "@/components/Icon";
import OrganizationSwitcher from "@/components/organizations/OrganizationSwitcher";
import MetaSetSelector from "@/components/widgets/meta/MetaSetSelector";
import { mapState } from 'vuex';
import ByMetaFieldsFilter from "@/components/widgets/meta/ByMetaFieldsFilter";
import InfoPanel from "@/components/InfoPanel";
import TileButton from "@/components/forms/TileButton";
import InfoHelper from "@/components/InfoHelper";
import DeletePrompt from "@/components/forms/DeletePrompt";
import Popup from "@/components/Popup";
import DateMixin from "@/components/mixins/DateMixin.js";
import LoadingPanel from "@/components/LoadingPanel";
import TeamAttacher from "@/components/organizations/TeamAttacher";
import { PivotPositions } from "@/enum";

export default {
  name: "FilterAddForm",
  components: {
    Button,
    Icon,
    OrganizationSwitcher,
    MetaSetSelector,
    ByMetaFieldsFilter,
    InfoPanel,
    TileButton,
    InfoHelper,
    DeletePrompt,
    Popup,
    LoadingPanel,
    TeamAttacher
  },
  mixins: [DateMixin],
  props: {
    organizationId: {type: String, default: ''},
    teams: {type: Array, default: () => {}},
    /**
     * @param filterId add-form switches into edit mode of filter ID given
     * */
    filterId: {type: String, default: ''}
  },
  data() {
    return {
      pivotPositions: PivotPositions,
      pivotPlacement: 'source',
      description: '',
      name: '',
      type: 'filter',
      enableSaving: false,
      error: '',
      localOrganizationId: this.organizationId,
      teamId: '',
      metaSetName: '',
      metaSetId: '',
      mergeMetaSetId: '',
      filter: '',
      axisMultiplier: [0,0,0],
      mode: 'exclude',
      hasLoaded: false,
      content: '',

      // deleting
      toBeDeletedAsset: null,
      showDeletePrompt: false,
      instanceList: {},
      loading: false,
      localTeams: [],

      pivotMetaSetId: '',
      pivotFields: [],
      pivotField: '',
      usePivotValues: false,
    };
  },
  computed: mapState({currentUserOrg: state => state.users.currentUserOrg,}),
  watch: {
    name() {
      this.error = null;
    },
    '$v.$invalid': function() {
      this.checkSaving();
    },
    organizationId(val) {
      if(val) {
        this.localOrganizationId = this.organizationId;
      }
    },
    currentUserOrg(val) {
      if(!this.organizationId) {
        this.localOrganizationId = val;
      }
    },
    filterId(val) {
      if(val) {
        this.loadFilterData();
      }
    },
    usePivotValues() {
      if(this.usePivotValues) {
        this.pivotField = 'sfx.asset.transform.preferredPivot'
       } else {
        this.pivotField = '';
      }
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(127),
      alphaNumSpace,
      async isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === '') {
          return true;
        }
        let bool = true;
        await this.$store.dispatch('checkIfFilterNameExists', {type: 'Asset', name: value}).then(data => {
          bool = (data.length === 0 || (data[0].id === this.filterId));
        });
        return bool;
      },
    },
    description: {
      maxLength: maxLength(1024),
    },
  },
  beforeMount() {
    if(this.filterId) {
      this.loadFilterData();
      this.loadAssetTeams();
    }
    else {
      this.localTeams = this.teams;
    }
  },
  methods: {
    setMetaSet(metaSetName, metaSetId) {
      this.metaSetId = metaSetId;
    },
    setPivotMetaSet(metaSetName, metaSetId) {
      this.pivotMetaSetId = metaSetId;
      this.$store.dispatch('loadMetaSet', {id: metaSetId, include: 'metaFields'}).then(metaSet => {
        this.pivotFields = metaSet.metaFields.map(field => {return {key: `${metaSet.name}.${field.key}`, description: field.description}});
      });
    },
    setMergingMetaSet(metaSetName, metaSetId) {
      this.mergeMetaSetId = metaSetId;
    },
    loadAssetTeams() {
      this.$store.dispatch('clientLoadAssetTeams', {
              id: this.filterId
            }).then(data => {
              this.localTeams = data;
            });
    },
    loadFilterData() {
      this.$store.dispatch('loadFilter', {
        id: this.filterId,
      }).then(data => {
        if(data) {
          this.name = data.name;
          this.description = data.description;
          if(data.content) {
            const content = JSON.parse(data.content);
            this.filter = content.filter;
            this.merge = content.merge;
            if(content.pipeline.subVersion.mode) { this.mode = content.pipeline.subVersion.mode;}
            if(content.pipeline.axisMultiplier){this.axisMultiplier = content.pipeline.axisMultiplier;}
            if(content.pipeline.pivotField) {
              this.pivotMetaSetId = content.pipeline.pivotMetaSetId;
              this.pivotField = content.pipeline.pivotField;
            }
            if(content.pipeline.pivotField) {
              this.usePivotValues = true;
            }
            if(content.pipeline.pivotPlacement) {
              this.pivotPlacement = content.pipeline.pivotPlacement;
            }
          }
        }
        this.hasLoaded = true;
      });
    },
    loadFilterInstances() {
        this.loading = true;
        let args = {
          listName: this.listId + 'filterinstances',
          add: {
            filter: {
              filterAddForm: 'assetId eq ' + this.filterId
            },
            include: {
              filterAddForm: ['asset', 'project'],
            }
          }
        };
        this.$store.dispatch('loadAllFilterInstances', args).then(data => {
          this.instanceList = data;
          this.loading = false;
        });
    },
    checkSaving() {
      this.enableSaving = this.name && !this.$v.$invalid;
    },
    filterChange(filter) {
      this.filter = filter;
    },
    mergeChange(merge) {
      this.merge = merge;
    },
    getArgs() {
      return {
        name: this.name,
        description: this.description,
        type: 'filter',

        content: JSON.stringify({
          pipeline: {
            axisMultiplier: [Number(this.axisMultiplier[0]), Number(this.axisMultiplier[1]), Number(this.axisMultiplier[2])],
            pivotPlacement: this.pivotPlacement,
            pivotField: this.pivotField,
            pivotMetaSetId: this.pivotMetaSetId,
            subVersion: {
              mode: this.mode
            },
            vhubPreProcessors: [{
              name: 'createYAMLFromMetaFilter',
              projectId: this.projectId,
            }],
          },
          filter: this.filter,
          finalizedFilter: this.finalizeFilter(),
          merge: this.merge,
          finalizedMerge: this.finalizeMerge(),
        })
      };
    },
    cleanupMerge() {
      const keys = Object.keys(this.merge);
      for(let i = 0; i < keys.length; i++) {
        if(!this.merge[keys[i]]) {
          delete this.merge[keys[i]];
        }
      }
    },
    /*
    * Removes all empty values from the filter
    * **/
    cleanupFilter() {
      const keys = Object.keys(this.filter);
      for(let i = 0; i < keys.length; i++) {
        if(!this.filter[keys[i]]) {
          delete this.filter[keys[i]];
        }
      }
    },
    finalizeFilter() {
      if(this.filter) {
        const keys = Object.keys(this.filter);
        let filterString = '';
        this.cleanupFilter();
        for(let i = 0; i < keys.length; i++) {
          if(i > 0 && filterString) {
            filterString += ',';
          }
          filterString += ` ${keys[i]} ${this.filter[keys[i]]}`;
        }
        return filterString;
      }
      return '';
    },
     finalizeMerge() {
      if(this.merge) {
        const keys = Object.keys(this.merge);
        let filterString = '';
        this.cleanupMerge();
        for(let i = 0; i < keys.length; i++) {
          if(i > 0 && filterString) {
            filterString += ',';
          }
          filterString += ` ${keys[i]} ${this.merge[keys[i]]}`;
        }
        return filterString;
      }
      return '';
    },
    update: async function() {
      let args = this.getArgs();
      args.id = this.filterId;
      await this.$store.dispatch('updateFilter', args).then(() => {
        this.$emit('finished');
      }).catch(e => {
        console.log(e);
        this.error = e;
      });
    },
    save: async function () {
      let args = this.getArgs();
      args.organizationId = this.localOrganizationId;
      if(this.teams) {
        args.teams = this.teams;
      }
      await this.$store.dispatch('createFilter', args).then(() => {
        this.$emit('finished');
      }).catch(e => {
        console.log(e);
        this.error = e;
      });
    },
    abort() {
      this.$emit('finished');
    },
    deleteFilter(id) {
      if(this.toBeDeletedAsset) {
        this.$store.dispatch('deleteFilters',
            [this.toBeDeletedAsset]
        ).then(() => {
          this.$emit('finished');
        });
        this.showDeletePrompt = false;
        this.toBeDeletedAsset = null;
      }
      else {
        this.toBeDeletedAsset = id;
        this.loadFilterInstances();
        this.showDeletePrompt = true;
      }
    },
  }
}
</script>