<template>
    <div>
      <div class="filter-background p-3 mb-2">
        <Button class="mt-1 mb-1 float-right" @click="clearFilter">{{ $t('clearFilter') }}</Button>
        <textarea
            @keydown="change"
            @keyup="change"
            class="form-text"
            v-model="filterString"
        />
        <div class="form-error" v-if="error">{{ $t('invalidJSON') }}</div>
      </div>
      <!--<div v-html="$t('metaFilterInstructions')" class="mb-2 lighter" />-->
      <div :key="key" v-for="(item, key) in metaFields">
        {{ item.description }}
        <meta-field-filter @value="updateFilterValue" :id="`${metaSetKey}.${item.key}`" :value="filter[`${metaSetKey}.${item.key}`]" />

        <!--<input type="text" class="form-text" v-model="filter[`${metaSetKey}.${item.key}`]" />-->
      </div>
    </div>
</template>

<script>
import Button from "@/components/forms/Button";

import MetaFieldFilter from "@/components/widgets/meta/MetaFieldFilter";
export default {
  name: "ByMetaFieldsFilter",
  components: {
    Button,
    MetaFieldFilter
  },
  props: {
      metaSetId: {type: String, required: true},
      organizationId: {type: String, default: ''},
      existingFilter: {type: Object, default: () => {}}
    },
    data() {
      return {
        metaFields: [],
        filter: {},
        filterString: '',
        metaSetKey: '',
        timeout: null,
        error: '',

      };
    },
    watch: {
      metaSetId() {
        //this.filter = {};
        this.loadMetaFields();
      },
      filter: {
        deep: true,
        handler() {
          this.filterString = JSON.stringify(this.filter);
          this.$emit('filterChange', this.filter);
        }
      },
      existingFilter(val) {
        if(val) {
          this.filter = this.existingFilter;
        }
      }
    },
    beforeMount() {
      this.loadMetaFields();
      if(this.existingFilter) {
        this.filter = this.existingFilter;
      }
    },
    methods: {
      change() {
        if(this.filterString) {
          let savable = true;
          try {
            JSON.parse(this.filterString);
          } catch {
            savable = false;
            this.error = true;
          }
          if(savable) {
            this.error = false;
            this.filter = JSON.parse(this.filterString);
          }
        }
        else {
          this.filter = {};
        }
      },
      clearFilter() {
        this.filter = {};
      },
      updateFilterValue(value, key) {
        this.filter[key] = value;
        this.filterString = JSON.stringify(this.filter);
        this.$emit('filterChange', this.filter);
      },
      loadMetaFields() {
        this.$store.dispatch('clientLoadMetaSet', {
          id: this.metaSetId,
          include: ['metaFields']
        }).then(metaSet => {
          this.metaSetKey = metaSet.name;
          if(metaSet) {
            this.metaFields = metaSet.metaFields;
          }
        }).catch(e => {
          console.log(e);
          this.error = this.$t('couldntBeLoaded');
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.filter-background {
  min-height: $input-default-height * 2;
  background-color: #7b7872;
  .form-text {
    width: 80%;
  }
}

</style>