<template>
  <select v-model="activeValue" @change="$emit('select', activeValue)" class="custom-select dropdown-widget">
    <option :key="key" :value="values[key]" v-for="(item, key) in labels">{{ $t(item) }}</option>
  </select>
</template>

<script>
export default {
  name: "DropDown",
  props: {
    labels: {type: Array, required: true},
    values: {type: Array, required: true},
    initialActiveValue: {type: String, default: null},
  },
  data() {
    return {
      activeValue: null,
    };
  },
  watch: {
    initialActiveValue() {
      this.activeValue = this.initialActiveValue;
    }
  },
  beforeMount() {
    this.activeValue = this.initialActiveValue ? this.initialActiveValue : this.values[0];
  },
}
</script>

<style lang="scss">
  .dropdown-widget {
    height: $input-default-height !important;
  }
</style>