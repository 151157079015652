<template>
  <div class="form-item">
    <drop-down class="dropdown" :initial-active-value="modifier" @select="updateModifier" :values="Object.values(modifiers)" :labels="Object.keys(modifiers)" />
    <input @keyup="combineValue()" type="text" class="form-text" v-model="localValue" >
  </div>
</template>

<script>
import DropDown from "@/components/forms/DropDown";
export default {
  name: "MetaFieldFilter",
  components: {
    DropDown,
  },
  props: {
    id: {type: String, required: true},
    value: {type: String, default: ''},
  },
  data() {
    return {
      localValue: '',
      modifier: 'eq',
      combinedValue: '',
      modifiers: {
        'greatherThan': 'gt',
        'lessThan': 'lt',
        'equals': 'eq',
        'contains': 'like',
      },
    };
  },
  watch: {
    value() {
      this.processInputVal();
    },
    combinedValue() {
      this.$emit('value', this.combinedValue, this.id);
    },
  },
  beforeMount() {
    this.localValue = this.value;
    this.processInputVal();
  },
  methods: {
    combineValue() {
      if(!this.localValue) {
        this.combinedValue = '';
        this.$emit('value', '', this.id);
      }
      else if(this.modifier === 'like') {
        this.combinedValue = `${this.modifier} *${this.localValue}*`;
      }
      else {
        this.combinedValue = `${this.modifier} ${this.localValue}`;
      }
      //this.$emit('value', this.combinedValue, this.id);
    },
    updateModifier(value) {
      this.modifier = value;
      this.combineValue();
    },
    processInputVal() {
      const keys = Object.values(this.modifiers);
      let cleanedUpString = this.value;
      // eq asdfasdf
      // like *das*
      const modifier = `[${keys.join('|')}]+`;
      const expressionRegExp = new RegExp(`(${modifier})\\s+(.*)`);
      if(cleanedUpString.match(expressionRegExp)) {
        let mod = RegExp.$1;
        let val = RegExp.$2;
        const res = val.match(`\\*(.*)\\*`);
        if(res && res[1]) {
          val = res[1];
        }
        this.modifier = mod;
        this.localValue = `${val}`;
      }
      else {
        this.localValue = this.value;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  $dropdown-width: 180px;
  .dropdown {
    width:$dropdown-width;
    position:absolute;
    left:0;
  }
  .form-text {
    width: 100%;
    padding-left: $dropdown-width + 10;
  }
</style>